:root {
  --primary-main: #00A0DF;
  --primary-main-hover: #1AAAE2;
  --primary-light: #6573c3;
  --primary-dark: #002D74;
  --primary-extra-dark:#00235B;
  --secondary-main: #f50057;
  --secondary-light: #f73378;
  --secondary-dark: #ab003c;
  --error-main: #f44336;
  --error-light: #e57373;
  --error-dark: #d32f2f;
  --font-canvas: #000000de;
  --font-canvas-secondary: #0000008a;
  --font-foreground:#270c0cde;
  --font-light:#fff;
  --page-background: #fff;
  --page-background-light: #fff;
  --grey-light: #b3cce285;
  /* rgba(0, 0, 0, 0.08); */
  --grey-medium: rgba(0, 0, 0, 0.38);
  --grey-dark: rgba(0, 0, 0, 0.54);

  --directPath-dark:#008802;
  --directPath-medium:#04BB07;
  --coursePath-dark:#002D74;
  --coursePath-medium:#00A0DF;
  --shopping-cart-badge:#00A0DF;
}



@font-face {
  font-family: 'Whitney';
  src: url('./fonts/whitney-book.eot');
  src: url('./fonts/whitney-book.eot?#iefix') format('embedded-opentype'),
       url('./fonts/whitney-book.woff') format('woff'),
       url('./fonts/whitney-book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'whitney';
  src: url('./fonts/Whitney-Semibold.eot');
  src: url('./fonts/Whitney-Semibold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/Whitney-Semibold.woff') format('woff'),
       url('./fonts/Whitney-Semibold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'whitney';
  src: url('./fonts/whitney-light.eot');
  src: url('./fonts/whitney-light.eot?#iefix') format('embedded-opentype'),
       url('./fonts/whitney-light.woff') format('woff'),
       url('./fonts/whitney-light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?hh5kbg');
  src:  url('./fonts/icomoon.eot?hh5kbg#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.woff2?hh5kbg') format('woff2'),
    url('./fonts/icomoon.ttf?hh5kbg') format('truetype'),
    url('./fonts/icomoon.woff?hh5kbg') format('woff'),
    url('./fonts/icomoon.svg?hh5kbg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
html {
  font-size:14px;
  font-weight: 400;
}

@media only screen and (min-width: 600px) {
  html{

    font-size:16px;
  }
}

body {
  margin: 0;
  font-family: "Whitney", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--page-background);
}

h1, h2, h3, h4, h5 { line-height: 1; font-weight:bold; }
h1 a, h2 a, h3 a, h4 a, h5 a { text-decoration: none; }
h1 { font-size: 42px; color: #002D74; margin-bottom: 26px; font-weight: 300; font-family: "industry"; }
h2 { font-size: 32px; color: #002968; margin-bottom: 20px; font-family: "industry"; font-weight: 400; }
h3 { font-size: 24px; color: #05090E; margin-bottom: 20px; font-weight:bold; }
h4 { font-size: 20px; margin-bottom: 15px; font-weight:bold; }
h5, h6 { font-size: 16px; color: #8F959F; margin-bottom: 10px; font-weight:bold; }
p {
  font-weight: 400;
}


[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.i-covid:before {
  content: "\e93e";
}
.i-tiktok:before {
  content: "\e93d";
}
.i-vegetarian:before {
  content: "\e936";
}
.i-vegan:before {
  content: "\e937";
}
.i-gluten:before {
  content: "\e938";
}
.i-halal:before {
  content: "\e939";
}
.i-warning2:before {
  content: "\e93c";
}
.i-heart-outline:before {
  content: "\e934";
}
.i-ellipsis:before {
  content: "\e932";
}
.i-ook:before {
  content: "\e92e";
}
.i-industry:before {
  content: "\e931";
}
.i-open:before {
  content: "\e900";
}
.i-mail:before {
  content: "\e901";
}
.i-menu:before {
  content: "\e902";
}
.i-up:before {
  content: "\e903";
}
.i-right:before {
  content: "\e904";
}
.i-left:before {
  content: "\e905";
}
.i-down:before {
  content: "\e906";
}
.i-arrow_drop_up:before {
  content: "\e907";
}
.i-arrow_drop_down:before {
  content: "\e908";
}
.i-home:before {
  content: "\e909";
}
.i-pencil:before {
  content: "\e90a";
}
.i-Business:before {
  content: "\e90b";
}
.i-computer:before {
  content: "\e90c";
}
.i-CorporateTrainingSolutions:before {
  content: "\e90d";
}
.i-chef:before {
  content: "\e90e";
}
.i-EnglishasaSecondLanguage:before {
  content: "\e90f";
}
.i-gear:before {
  content: "\e910";
}
.i-leaf:before {
  content: "\e911";
}
.i-wrench:before {
  content: "\e912";
}
.i-heart:before {
  content: "\e913";
}
.i-search:before {
  content: "\e914";
}
.i-export:before {
  content: "\e915";
}
.i-log-out:before {
  content: "\e916";
}
.i-login:before {
  content: "\e917";
}
.i-snapchat:before {
  content: "\e918";
}
.i-newspaper:before {
  content: "\e919";
}
.i-image:before {
  content: "\e91a";
}
.i-images:before {
  content: "\e91b";
}
.i-camera:before {
  content: "\e91c";
}
.i-play:before {
  content: "\e91d";
}
.i-bullhorn:before {
  content: "\e91e";
}
.i-connection:before {
  content: "\e91f";
}
.i-delivery-icon:before {
  content: "\e920";
}
.i-books:before {
  content: "\e921";
}
.i-library:before {
  content: "\e922";
}
.i-outcome-icon:before {
  content: "\e923";
}
.i-profile:before {
  content: "\e924";
}
.i-parent:before {
  content: "\e925";
}
.i-doc-file:before {
  content: "\e926";
}
.i-file-text2:before {
  content: "\e927";
}
.i-file-picture:before {
  content: "\e928";
}
.i-pdf-file:before {
  content: "\e929";
}
.i-xls-file:before {
  content: "\e92a";
}
.i-sharecircle:before {
  content: "\e92b";
}
.i-podcast:before {
  content: "\e92c";
}
.i-flipboard:before {
  content: "\e92d";
}
.i-folder:before {
  content: "\e92f";
}
.i-folder-open:before {
  content: "\e930";
}
.i-price-tag:before {
  content: "\e935";
}
.i-cart:before {
  content: "\e93a";
}
.i-coin-dollar:before {
  content: "\e93b";
}
.i-phone:before {
  content: "\e942";
}
.i-location:before {
  content: "\e947";
}
.i-compass2:before {
  content: "\e94a";
}
.i-bell:before {
  content: "\e951";
}
.i-mobile:before {
  content: "\e958";
}
.i-bubbles:before {
  content: "\e96d";
}
.i-user:before {
  content: "\e971";
}
.i-lock:before {
  content: "\e98f";
}
.i-mug:before {
  content: "\e9a2";
}
.i-spoon-knife:before {
  content: "\e9a3";
}
.i-meter:before {
  content: "\e9a7";
}
.i-lab:before {
  content: "\e9aa";
}
.i-bin:before {
  content: "\e9ac";
}
.i-briefcase:before {
  content: "\e9ae";
}
.i-menu2:before {
  content: "\e9bd";
}
.i-download2:before {
  content: "\e9c5";
}
.i-earth:before {
  content: "\e9ca";
}
.i-link2:before {
  content: "\e9cb";
}
.i-bookmark:before {
  content: "\e9d2";
}
.i-warning:before {
  content: "\ea07";
}
.i-question:before {
  content: "\ea09";
}
.i-plus:before {
  content: "\ea0a";
}
.i-minus:before {
  content: "\ea0b";
}
.i-info:before {
  content: "\ea0c";
}
.i-cancel-circle:before {
  content: "\ea0d";
}
.i-cross:before {
  content: "\ea0f";
}
.i-checkmark:before {
  content: "\ea10";
}
.i-checkbox-checked:before {
  content: "\ea52";
}
.i-checkbox-unchecked:before {
  content: "\ea53";
}
.i-radio-checked:before {
  content: "\ea54";
}
.i-radio-unchecked:before {
  content: "\ea56";
}
.i-share2:before {
  content: "\ea82";
}
.i-facebook:before {
  content: "\ea91";
}
.i-instagram:before {
  content: "\ea92";
}
.i-twitter:before {
  content: "\ea96";
}
.i-youtube:before {
  content: "\ea9d";
}
.i-flickr2:before {
  content: "\eaa4";
}
.i-linkedin:before {
  content: "\eac9";
}
.i-star:before {
  content: "\f005";
}
.i-star-o:before {
  content: "\f006";
}
.i-clock-o:before {
  content: "\f017";
}
.i-book:before {
  content: "\f02d";
}
.i-print:before {
  content: "\f02f";
}
.i-edit:before {
  content: "\f044";
}
.i-play2:before {
  content: "\f04b";
}
.i-calendar:before {
  content: "\f073";
}
.i-external-link:before {
  content: "\f08e";
}
.i-rss:before {
  content: "\f09e";
}
.i-chain:before {
  content: "\f0c1";
}
.i-angle-double-left:before {
  content: "\f100";
}
.i-angle-double-right:before {
  content: "\f101";
}
.i-angle-left:before {
  content: "\f104";
}
.i-angle-right:before {
  content: "\f105";
}
.i-compass:before {
  content: "\f14e";
}
.i-bank:before {
  content: "\f19c";
}
.i-graduation-cap:before {
  content: "\f19d";
}
.i-pie-chart:before {
  content: "\f200";
}
.i-subway:before {
  content: "\f239";
}
.i-calendar-plus-o:before {
  content: "\f271";
}
.i-calendar-check-o:before {
  content: "\f274";
}
.i-pencil1:before {
  content: "\e933";
}
.i-copy:before {
  content: "\e93f";
}
.i-pushpin:before {
  content: "\e946";
}
.i-enlarge:before {
  content: "\e989";
}
.i-shrink:before {
  content: "\e98a";
}
.i-checklist:before {
  content: "\e9b8";
}
.i-heart1:before {
  content: "\e9da";
}
.i-exit:before {
  content: "\ea14";
}
.i-spinner11:before {
  content: "\e984";
}
