:root {
  --white: #fff;
  --light: #f8f9fa;
}
.quiz-list-header {
  padding: 10px;
  margin: 0 0 10px 0;
  background-color: rgba(30, 75, 138, 1);
  color: var(--white);
  font-weight: bolder;
}
.row.quiz-container {
  background-color: var(--light);
  margin: 0 0 10px 0;
}
.row.quiz-container.loaded.open {
  border: 2px solid rgba(63, 155, 217, 1);
  margin: 0 0 10px 0;
}
.row.quiz-header {
  padding: 10px;
  cursor: pointer;
  background-color: rgba(238, 235, 232, 1);
}
.row.quiz-header .remove {
  text-align: right;
  color: rgba(255, 41, 71, 1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: x-large;
  cursor: pointer;
}
.row.quiz-content {
  margin: 0;
}
.row.quiz-content .student-answer {
  clear: both;
}
.row.quiz-container.closed .quiz-content {
  display: none;
}
.row.quiz-header {
  font-weight: bolder;
  background-color: rgba(63, 155, 217, 1);
  color: var(--white);
}
.row.quiz-container.open .quiz-content {
  padding: 0 10px;
}
.row.quiz-container.closed .quiz-header {
  font-weight: bolder;
  background-color: rgba(63, 155, 217, 1);
  color: var(--white);
}
/* Results */
.quiz-contianer {
  margin: 0 15px;
}
.quiz-contianer .quiz-head {
  border-bottom: 2px solid #dee2e6;
  color: #495057;
}
.quiz-contianer .quiz-head .col-sm-12 {
  padding: 5px 0;
}
.quiz-contianer .quiz-head .col-sm-12:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.quiz-contianer .quiz-head .head-title {
  font-weight: bold;
  width: 15%;
  padding: 0 5px 0 0;
}

.quiz-contianer .row.quiz-legend {
  padding: 10px 10px;
  font-weight: bold;
  color: #495057;
  border-bottom: 2px solid #dee2e6;
}
.quiz-contianer .quiz-legend .student-answer {
  text-align: right;
}
.quiz-contianer .row.quiz-result {
  padding: 5px 10px;
  border-bottom: 1px solid #dee2e6;
}
.quiz-contianer .row.quiz-result:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.quiz-contianer .row.quiz-result:hover {
  background-color: var(--light);
  transition: 0.25s ease;
  cursor: pointer;
}
.quiz-contianer .row.quiz-result.incorrect {
  background-color: rgba(255, 41, 71, 1);
  color: var(--white);
}
.quiz-contianer .row.quiz-result.half-mark {
  background-color: rgba(241, 149, 28, 1);
  color: var(--white);
}
.quiz-contianer .quiz-result .question-number {
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
}
.quiz-contianer .quiz-result .student-answer {
  text-align: right;
}

.mark-result {
  margin: 0 !important;
  padding: 10px 0;
  background-color: rgba(51, 136, 208, 0.7);
  font-weight: bold;
  color: var(--white);
}
.quiz-results .mark-result {
  margin: 0 -25px;
}
.mark-result .mark-result__score,
.mark-result .mark-result__percent {
  text-align: right;
}
.quiz-head .col-sm-6:nth-child(odd) .head-title {
  padding-left: 16px !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-sm {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-sm-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-sm-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-sm-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-sm-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-sm-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-sm-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-sm-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-sm-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-sm-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-sm-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-sm-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-sm-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-sm-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.order-sm-first {
  -ms-flex-order: -1;
  order: -1;
}
.order-sm-last {
  -ms-flex-order: 13;
  order: 13;
}
.order-sm-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-sm-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-sm-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-sm-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-sm-4 {
  -ms-flex-order: 4;
  order: 4;
}
.order-sm-5 {
  -ms-flex-order: 5;
  order: 5;
}
.order-sm-6 {
  -ms-flex-order: 6;
  order: 6;
}
.order-sm-7 {
  -ms-flex-order: 7;
  order: 7;
}
.order-sm-8 {
  -ms-flex-order: 8;
  order: 8;
}
.order-sm-9 {
  -ms-flex-order: 9;
  order: 9;
}
.order-sm-10 {
  -ms-flex-order: 10;
  order: 10;
}
.order-sm-11 {
  -ms-flex-order: 11;
  order: 11;
}
.order-sm-12 {
  -ms-flex-order: 12;
  order: 12;
}
.offset-sm-0 {
  margin-left: 0;
}
.offset-sm-1 {
  margin-left: 8.333333%;
}
.offset-sm-2 {
  margin-left: 16.666667%;
}
.offset-sm-3 {
  margin-left: 25%;
}
.offset-sm-4 {
  margin-left: 33.333333%;
}
.offset-sm-5 {
  margin-left: 41.666667%;
}
.offset-sm-6 {
  margin-left: 50%;
}
.offset-sm-7 {
  margin-left: 58.333333%;
}
.offset-sm-8 {
  margin-left: 66.666667%;
}
.offset-sm-9 {
  margin-left: 75%;
}
.offset-sm-10 {
  margin-left: 83.333333%;
}
.offset-sm-11 {
  margin-left: 91.666667%;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

@media print {
  body {
    color-adjust: exact;
    background-color: none;
  }
}
@page {
  margin: 1cm;
}
